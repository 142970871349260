import React from 'react';
import { Parallax } from 'react-scroll-parallax';

import dataForAdvantages from '../../../information/CrossPlatform/dataForAdvantages';

import {
  ParallaxPhone,
  IosTile,
  AndroidTile,
} from '../../../images/CrossPlatform/Graphics';

import './Advantages.scss';

export default function Advantages() {
  return (
    <div className='advantages-container'>
      <div className='advantages-wrapper'>
        <div className='advantages-phone-container'>
          <Parallax y={[0, 40]} className='ios-tile-container-large'>
            <IosTile />
          </Parallax>
          <Parallax y={[0, 40]} className='android-tile-container-large'>
            <AndroidTile />
          </Parallax>
          <Parallax y={[0, 55]} className='ios-tile-container-medium'>
            <IosTile />
          </Parallax>
          <Parallax y={[0, 55]} className='android-tile-container-medium'>
            <AndroidTile />
          </Parallax>
          <Parallax y={[0, 45]} className='ios-tile-container-small'>
            <IosTile />
          </Parallax>
          <Parallax y={[0, 45]} className='android-tile-container-small'>
            <AndroidTile />
          </Parallax>
          <div className='screen-container'>
            <ParallaxPhone />
          </div>
        </div>
        <div className='advantages-text-container'>
          <h3 className='advantages-header'>
            Cross-platform development provides a number of significant
            advantages:
          </h3>
          {dataForAdvantages.map(item => {
            const { id, header, text } = item
            return (
              <div className='advantage-example' key={id}>
                <h4 className='advantage-example-header'>{header}</h4>
                <span className='advantage-example-text'>{text}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
