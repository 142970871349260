import {
  Android,
  Chat,
  Flutter,
  IOS,
  ReactIcon,
  Tools,
} from "../../images/CrossPlatform/Icons"

const dataForCustomServices = [
  {
    id: "1",
    icon: Tools,
    header: "Mobile App UI/UX Design",
    text:
      "Our approach to UI/UX is all about freedom, freedom of our customers to implement their ideas. We combine that with our technical expertise and compliance with the latest market trends and you have the best-selling recipe for your business.",
  },
  {
    id: "2",
    icon: Chat,
    header: "Mobile App Quality Assurance",
    text:
      "Client’s success is our core goal. We are carefully testing every developed mobile application for bugs, performance, dedicated networking and quality.",
  },
  {
    id: "3",
    icon: ReactIcon,
    header: "React Native Mobile App Development",
    text:
      "React Native is an innovative technology developed by Facebook. Our React Native development team can provide you with business-boosting solutions by using the latest practices and methodologies.",
  },
  {
    id: "4",
    icon: Flutter,
    header: "Flutter Mobile App Development",
    text:
      "Based on the Flutter, which is represented by Google, we can take your business to the next level with high-quality hybrid applications development services for iOS & Android.",
  },
  {
    id: "5",
    icon: IOS,
    header: "iOS Mobile App Development",
    text:
      "Based on our client’s preferences, we develop unique iOS mobile applications for different businesses all over the globe. ",
  },
  {
    id: "6",
    icon: Android,
    header: "Android Mobile App Development",
    text:
      "We excel in the use of modern technologies by developing top-notch Android apps.",
  },
]

export default dataForCustomServices
