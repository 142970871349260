import React from "react"

import dataForCustomServices from "../../../information/CrossPlatform/dataForCustomServices"

import "./CustomServices.scss"

export default function CustomServices() {
  return (
    <div className="cs-container">
      <div className="cs-wrapper">
        <h3 className="cs-header">
          Custom Mobile Application Development Services
        </h3>
        <div className="cs-text-container">
          {dataForCustomServices.map(item => {
            const { id, icon, header, text } = item
            return (
              <div className="cs-article" key={id}>
                <div className="cs-article-header-container">
                  <img src={icon} className="cs-article-icon" />
                  <h4 className="cs-article-header">{header}</h4>
                </div>
                <div className="cs-article-text">{text}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
