const dataForProjects = [
  {
    image: "Football Club Fan Application",
    title: "Football Club Fan App",
    alt: "Football Club Fan App",
    info:
      "Social mobile app was designed for fans of a particular football team, where they can follow all the news of their favourite players and do lots of other things.",
    link: "/cases/fcteam/",
    tags: ["all", "mobile"],
    tagBadges: [
      {
        id: "1a",
        text: "#mobile",
      },
    ],
  },
  {
    image: "Remote Assistance",
    title: "Remote Assistance",
    alt: "Remote Assistance",
    info:
      "The purpose of App is to facilitate communication between technicians and users, and with the help of such an application it will be possible to carry out repairs remotely.",
    link: "/cases/webrtc/",
    tags: ["all", "mobile", "web", "WebRTC"],
    tagBadges: [
      {
        id: "2a",
        text: "#mobile",
      },
      {
        id: "2b",
        text: "#web",
      },
      {
        id: "2c",
        text: "#WebRTC",
      },
    ],
  },
  {
    image: "Social App",
    title: "Social App",
    alt: "Social App",
    info:
      "This application helps organize events with your friends in just 3 clicks. It is intended for youngsters to be able to control sending invitations and attendance.",
    link: "/cases/social-app/",
    tags: ["all", "mobile"],
    tagBadges: [
      {
        id: "3a",
        text: "#mobile",
      },
    ],
  },
]

export default dataForProjects
