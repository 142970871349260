import React from "react"
import { withController } from "react-scroll-parallax"

class IosTile extends React.Component {
  handleLoad = () => {
    this.props.parallaxController.update()
  }

  render() {
    return <img src={require("./IosTile.svg")} onLoad={this.handleLoad} />
  }
}

export default withController(IosTile)
