import React from "react"

import { FountainRobot } from "../../../images/CrossPlatform/Graphics"

import "./Banner.scss"

export default function Banner() {
  return (
    <div className="crp-banner-container">
      <div className="crp-banner-wrapper">
        <div className="crp-header-container">
          <h1 className="crp-banner-header">Cross-Platform</h1>
          <h5 className="crp-banner-subheader">mobile apps development</h5>
        </div>
        <div className="fountain-robot-container">
          <FountainRobot />
        </div>
      </div>
    </div>
  )
}
