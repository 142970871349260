const dataForAdvantages = [
  {
    id: "1",
    header: "Reusable code",
    text:
      "our team can write the code once and then export your app to many platforms without having to create a dedicated application for every single one of them.",
  },
  {
    id: "2",
    header: "Efficiency",
    text:
      "With our cross-platform development services, the development process is more straightforward and comfortable to be managed.",
  },
  {
    id: "3",
    header: "Cost savings",
    text:
      "With cross-platform development services, you won’t require multiple teams for different versions of your mobile app. OS-System will provide you with a dedicated team which can accelerate its work on different platforms at the same time due to the unified codebase.",
  },
  {
    id: "4",
    header: "Greater market reach",
    text:
      "By publishing your mobile application on multiple platforms, you will boost your business by building a more extensive user base and generating a higher ROI on your app.",
  },
]

export default dataForAdvantages
