import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Banner from "../../components/CrossPlatform/Banner"
import CustomServices from "../../components/CrossPlatform/CustomServices"
import Advantages from "../../components/CrossPlatform/Advantages"
import Cases from "../../components/CommonComponents/Cases"
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise"
import { ParallaxProvider } from "react-scroll-parallax"
import dataForProjects from "../../information/CrossPlatform/dataForProjects"
import { mainUrl } from "../../js/config"

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1021,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
      },
    },
  ],
}

export default function CrossPlatform() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : ""
  return (
    <div>
      <SEO
        title="Cross-platform app development for mobile app in OSSystem"
        description="Create an app for iOS and Android within reduced timeframe and on budget with our cross-platform mobile app development services from OSSystems experts"
        canonical={`${mainUrl}/expertise/cross-platform/`}
      />
      <ParallaxProvider>
        <Layout showFormPoint={400}>
          <Banner />
          <CustomServices />
          <Advantages />
          <Cases
            projects={dataForProjects}
            settings={settings}
            headerText="Work Projects"
          />
          <ContactUsExpertise form={0} url={sendUrl} />
        </Layout>
      </ParallaxProvider>
    </div>
  )
}
